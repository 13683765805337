import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				SkyHigh Marketing Agency
			</title>
			<meta name={"description"} content={"Eleve su marca, alcance nuevas alturas"} />
			<meta property={"og:title"} content={"SkyHigh Marketing Agency"} />
			<meta property={"og:description"} content={"Eleve su marca, alcance nuevas alturas"} />
			<meta property={"og:image"} content={"https://harmonichavn.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://harmonichavn.com/img/5347474357.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://harmonichavn.com/img/5347474357.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://harmonichavn.com/img/5347474357.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://harmonichavn.com/img/5347474357.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://harmonichavn.com/img/5347474357.png"} />
			<meta name={"msapplication-TileImage"} content={"https://harmonichavn.com/img/5347474357.png"} />
			<meta name={"msapplication-TileColor"} content={"https://harmonichavn.com/img/5347474357.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-primary" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Contacte con nosotros{"  "}
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
				Nos encantaría saber de usted y discutir cómo SkyHigh puede ayudar a elevar sus esfuerzos de marketing. Póngase en contacto con nosotros a través de los siguientes:{"\n\n"}
				</Text>
				<Link
					href="mailto:info@harmonichavn.com"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					info@harmonichavn.com
				</Link>
				<Link
					href="tel:+34 952 73 30 21"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+34 952 73 30 21
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					C. Piedras 66, Local 2, 29120 Alhaurín el Grande, Málaga, España
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});